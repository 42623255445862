body, html, .bg-izidev, #root{
  height: 100%;
  width: 100%;
}

html {
  overflow-y: scroll; 
  scrollbar-gutter: stable;
}

#logo-izidev{
  height: 80px;
  margin-bottom: 20px;
}

.bg-izidev{
  background: url(./../img/bg-christmas.jpg) no-repeat;
  background-size: cover;
  background-position: center fixed;
  position: fixed;
  height: 100%;
  width: 100%;
}

.bg-izidev:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  /* background: linear-gradient(-45deg, #152649 0%, #4f525c 100%); */
  background: linear-gradient(-45deg, #152649 50%, #4f525c 100%);
  top: 0;
  left: 0;
  z-index: -1;
  opacity: .8;
  position: fixed;
}

.bg-izidev{
  background: url(./../img/bg-christmas.jpg) no-repeat;
  background-size: cover;
  background-position: center fixed;
  position: fixed;
  height: 100%;
  width: 100%;
}

.bg-izidev:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  /* background: linear-gradient(-45deg, #152649 0%, #4f525c 100%); */
  background: linear-gradient(-45deg, #152649 50%, #4f525c 100%);
  top: 0;
  left: 0;
  z-index: -1;
  opacity: .8;
  position: fixed;
}



.bg-izidev-new{
  background: url(./../img/background/bg-business.jpg) no-repeat;
  background-size: cover;
  background-position: right;
}

.modal-open{
  width: 100% !important;
  padding-right: 0px !important;
}

.swal2-shown .swal2-height-auto{
  height: 100% !important;
  width: 100% !important;
  padding-right: 0px !important;
}

#izidev2023{
  padding: 0 !important;
}


@media (max-width: 768px) {
  #izidev2023 .bg-izidev-new {
    display: none;
  } 
}



.bg-dark-blue{
  background-color: #1d263a;
  background: linear-gradient(-45deg, #3b4559 0%, #1d263a 100%);
}


.btn-izidev{
  background-color: #1d263a !important;
  color: #ffffff !important;
}

.badge-info{
  background-color: #11cdef !important;
  color: #ffffff;
}

.badge-danger{
  background-color: #f5365c !important;
  color: #ffffff;
}

.badge-warning{
  background-color: #fb6340 !important;
  color: #ffffff;
}

.badge-primary{
  background-color: #5e72e4 !important;
  color: #ffffff;
}

.badge-success{
  background-color: #2dce89 !important;
  color: #ffffff;
}

.badge-default{
  background-color: #172b4d !important;
  color: #ffffff;
}

.badge-secondary{
  background-color: #f4f5f7 !important;
  color: #32325d;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.font-10{
  font-size: 10px !important;
}
.font-12{
  font-size: 12px !important;
}
.font-14{
  font-size: 14px !important;
}
.font-16{
  font-size: 16px !important;
}
.font-18{
  font-size: 18px !important;
}
.font-20{
  font-size: 20px !important;
}
.font-22{
  font-size: 22px !important;
}
.font-24{
  font-size: 24px !important;
}
.font-26{
  font-size: 26px !important;
}
.font-28{
  font-size: 28px !important;
}


.card-hover:hover{
  background: rgba(94, 173, 238, 0.2);
  cursor: pointer;
}


.timeline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative
}

.timeline:before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 5px;
    background: #2d353c;
    left: 20%;
    margin-left: -2.5px
}

.timeline>li {
    position: relative;
    min-height: 50px;
    padding: 5px 0
}

.timeline .timeline-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: right;
    top: 30px
}

.timeline .timeline-time .date,
.timeline .timeline-time .time {
    display: block;
    font-weight: 600
}

.timeline .timeline-time .date {
    line-height: 16px;
    font-size: 12px
}

.timeline .timeline-time .time {
    line-height: 24px;
    font-size: 20px;
    color: #242a30
}

.timeline .timeline-icon {
    left: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 40px
}

.timeline .timeline-icon a {
    text-decoration: none;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    background: #d9e0e7;
    line-height: 10px;
    color: #fff;
    font-size: 14px;
    border: 5px solid #2d353c;
    transition: border-color .2s linear
}

.timeline .timeline-body {
    margin-left: 23%;
    margin-right: 17%;
    background: #fff;
    position: relative;
    padding: 20px 25px;
    border-radius: 6px
}

.timeline .timeline-body:before {
    content: '';
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #fff;
    left: -20px;
    top: 20px
}

.timeline .timeline-body>div+div {
    margin-top: 15px
}

.timeline .timeline-body>div+div:last-child {
    margin-bottom: -20px;
    padding-bottom: 20px;
    border-radius: 0 0 6px 6px
}

.timeline-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e7eb;
    line-height: 30px
}

.timeline-header .userimage {
    float: left;
    width: 34px;
    height: 34px;
    border-radius: 40px;
    overflow: hidden;
    margin: -2px 10px -2px 0
}

.timeline-header .username {
    font-size: 16px;
    font-weight: 600
}

.timeline-header .username,
.timeline-header .username a {
    color: #2d353c
}

.timeline img {
    max-width: 100%;
    display: block
}

.timeline-content {
    letter-spacing: .25px;
    line-height: 18px;
    font-size: 13px
}

.timeline-content:after,
.timeline-content:before {
    content: '';
    display: table;
    clear: both
}

.timeline-title {
    margin-top: 0
}

.timeline-footer {
    background: #fff;
    border-top: 1px solid #e2e7ec;
    padding-top: 15px
}

.timeline-footer a:not(.btn) {
    color: #575d63
}

.timeline-footer a:not(.btn):focus,
.timeline-footer a:not(.btn):hover {
    color: #2d353c
}

.timeline-likes {
    color: #6d767f;
    font-weight: 600;
    font-size: 12px
}

.timeline-likes .stats-right {
    float: right
}

.timeline-likes .stats-total {
    display: inline-block;
    line-height: 20px
}

.timeline-likes .stats-icon {
    float: left;
    margin-right: 5px;
    font-size: 9px
}

.timeline-likes .stats-icon+.stats-icon {
    margin-left: -2px
}

.timeline-likes .stats-text {
    line-height: 20px
}

.timeline-likes .stats-text+.stats-text {
    margin-left: 15px
}

.timeline-comment-box {
    background: #f2f3f4;
    margin-left: -25px;
    margin-right: -25px;
    padding: 20px 25px
}

.timeline-comment-box .user {
    float: left;
    width: 34px;
    height: 34px;
    overflow: hidden;
    border-radius: 30px
}

.timeline-comment-box .user img {
    max-width: 100%;
    max-height: 100%
}

.timeline-comment-box .user+.input {
    margin-left: 44px
}

.lead {
    margin-bottom: 20px;
    font-size: 21px;
    font-weight: 300;
    line-height: 1.4;
}

.text-danger, .text-red {
    color: #ff5b57!important;
}

.cursor-pointer{
  cursor: pointer;
}

.verde-claro{
  background: #baf3db !important;
}
.amarelo-claro{
  background: #f8e6a0 !important;
}
.laranja-claro{
  background: #ffe2bd !important;
}
.vermelho-claro{
  background: #ffd2cc !important;
}
.roxo-claro{
  background: #dfd8fd !important;
}
.azul-claro{
  background: #cce0ff !important;
}
.azulCeu-claro{
  background: #c1f0f5 !important;
}
.lima-claro{
  background: #d3f1a7 !important;
}
.rosa-claro{
  background: #fdd0ec !important;
}
.preto-claro{
  background: #dcdfe4 !important;
}





.btn-upload {
  border: 1px solid #dfdfdf;
  padding: 10px;
  border-radius: 10px;
}

.btn-upload::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #16365f;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

.btn-upload::file-selector-button:hover {
  background: #16365f;
}


.table-responsive{
  min-height: 250px !important;
}


.ql-editor p {
  font-size: 16px !important;
}
.ql-editor h1 {
  font-size: 26px !important;
}
.ql-editor h2 {
  font-size: 24px !important;
}
.ql-editor h3 {
  font-size: 20px !important;
}
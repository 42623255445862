.overflow-none {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.switch-div {
    @media screen and (max-width: 1252px) {
        margin-top: 1rem;
    }
}

.margin-button{
    @media screen and (max-width: 1252px) {
        margin-top: 0.8rem;
    }
}
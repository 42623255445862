.overflow-none {
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  overflow: hidden !important;
  -webkit-box-orient: vertical !important; 
  }

  .chart_container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
  }

  .chart_line_resume {
    flex-shrink: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
  }

  .font-style{
    font-size: 1.1rem;
  }

  @media screen and (max-width: 1059px) {
    .font-style{
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 1009px) {
    .font-style{
      font-size: 0.9rem;
    }
  }
  
  @media screen and (max-width: 450px) {
    .card-header h2{
      font-size: 1rem;
    }

    .table-wrapper {
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    .responsive-table {
      width: 100%;
    }
  
    .table-text {
      font-size: 0.9rem;
    }
  }
